<template>
  <div data-app>
    <div class="card card-custom card-stretch gutter-b">
      <div class="card-body pt-3 pb-0">
        <div class="row">
          <div
            class="col-md-2"
            v-if="Number(currentUserPersonalInfo.perfil_id) === 1"
          >
            <v-autocomplete
              v-model="mandante"
              :items="mandantes"
              item-value="mandanteID"
              item-text="mandanteNombre"
              @change="getCampaigns"
              label="Mandante"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-md-3">
            <v-autocomplete
              v-model="campaign"
              :items="campaigns"
              label="Campaña"
              item-key="campaignID"
              item-value="campaignID"
              item-text="nombre"
              dense
            ></v-autocomplete>
          </div>
          <div class="col-md-2">
            <v-menu
              ref="datepickerDesde"
              v-model="datepickerDesde"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaDesde"
                  label="Desde"
                  v-bind="attrs"
                  v-on="on"
                  class="m-0 p-0"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaDesdeOriginal"
                @input="datepickerDesde = false"
                :max="new Date().toISOString().slice(0, 10)"
                no-title
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="col-md-2">
            <v-menu
              ref="datepickerHasta"
              v-model="datepickerHasta"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="fechaHasta"
                  label="Hasta"
                  v-bind="attrs"
                  v-on="on"
                  class="m-0 p-0"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="fechaHastaOriginal"
                @input="datepickerHasta = false"
                :max="new Date().toISOString().slice(0, 10)"
                no-title
              ></v-date-picker>
            </v-menu>
          </div>
          <div class="col-md-3">
            <v-btn color="primary" dark class="mb-2" v-on:click="showTasks()">
              Filtrar
            </v-btn>
          </div>
        </div>
      </div>
    </div>
    <v-row v-if="dialogShowTask">
      <v-col cols="12">
        <div class="card card-custom card-stretch">
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">Tareas</h3>
            </div>
          </div>
          <div class="card-body p-5 pb-7">
            <v-row>
              <v-col cols="12">
                <v-btn
                  color="primary float-right"
                  dark
                  class="mb-2"
                  v-on:click="
                    downloadExcelFile(
                      'tareas',
                      'task_table',
                      headersTasks.length
                    )
                  "
                >
                  Exportar
                </v-btn>
              </v-col>
              <v-col cols="12">
                <v-data-table
                  :headers="headersTasks"
                  :items="tasks"
                  class="table-striped elevation-1"
                  hide-default-footer
                  disable-pagination
                  id="task_table"
                >
                </v-data-table>
              </v-col>
            </v-row>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import draggable from "vuedraggable";
import moment from "moment";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import KTUtil from "@/assets/js/components/util";
import { GET_CAMPAIGNS_MANDANTE } from "@/core/services/store/campaign.module";
import { GET_MANDANTES } from "@/core/services/store/mandante.module";
import { GET_TASKS_EXPORT } from "@/core/services/store/task.module";
import { mapGetters } from "vuex";
import XLSX from "sheetjs-style";
import Swal from "sweetalert2";
export default {
  name: "tasks-export",
  components: {
    //eslint-disable-next-line
    draggable
  },
  data() {
    return {
      dialogShowTask: false,
      campaign: null,
      campaigns: [],
      mandante: null,
      mandantes: [],
      fechaDesde: null,
      fechaDesdeOriginal: null,
      datepickerDesde: false,
      fechaHasta: null,
      fechaHastaOriginal: null,
      datepickerHasta: false,
      headersTasks: [
        {
          text: "ID Campaña",
          value: "campaignID",
        },
        {
          text: "Mandante",
          value: "campaignMandante",
        },
        {
          text: "Campaña",
          value: "campaignNombre",
        },
        {
          text: "Descripción",
          value: "campaignDescripcion",
        },
        {
          text: "Tipo de Campaña",
          value: "campaignTipo",
        },
        {
          text: "Estado de Campaña",
          value: "campaignEstado",
        },
        {
          text: "Fecha Inicio",
          value: "campaignFechaInicio",
        },
        {
          text: "Fecha Termino",
          value: "campaignFechaTermino",
        },
        {
          text: "Dias Campaña",
          value: "campaignDiasTotales",
        },
        {
          text: "Dias Transcurridos",
          value: "campaignDiasTranscurridos",
        },
        {
          text: "Dias Restantes",
          value: "campaignDiasRestantes",
        },
        {
          text: "Tareas Campaña",
          value: "campaignCantidadTareas",
        },
        {
          text: "Tareas Asignadas",
          value: "campaignCantidadTareasAsignadas",
        },
        {
          text: "Tareas Realizadas",
          value: "campaignCantidadTareasRealizadas",
        },
        {
          text: "ID Tarea",
          value: "taskID",
        },
        {
          text: "Estado de Tarea",
          value: "taskEstado",
        },
        {
          text: "Fecha de Asignación",
          value: "taskFechaAsignacion",
        },
        {
          text: "Fecha de Realización",
          value: "taskFechaGestion",
        },
        {
          text: "Jobber",
          value: "taskJobber",
        },
        {
          text: "Latitud",
          value: "latitud",
        },
        {
          text: "Longitud",
          value: "longitud",
        },
        {
          text: "Latitud Jobber",
          value: "latitud_jobber",
        },
        {
          text: "Longitud Jobber",
          value: "longitud_jobber",
        },
        {
          text: "Distancia",
          value: "distancia_tarea_jobber",
        },
      ],
      tasks: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Exportar Tareas", route: "tasks" },
    ]);
  },
  created() {
    this.initialize();
  },
  methods: {
    initialize() {
      this.getMandantes();
    },
    getMandantes() {
      KTUtil.setLoading();
      let oVue = this;
      oVue.mandantes = [];
      oVue.$store.dispatch(GET_MANDANTES).then((mandantes) => {
        mandantes.unshift({ mandanteID: null, mandanteNombre: "" });
        oVue.mandantes = mandantes;
        if (Number(oVue.currentUserPersonalInfo.perfil_id) === 2) {
          oVue.mandante = oVue.currentUserPersonalInfo.mandanteID;
          oVue.getCampaigns();
        }
        KTUtil.removeLoading();
      });
    },
    getCampaigns() {
      let oVue = this;
      oVue.campaigns = [];
      if (oVue.mandante) {
        KTUtil.setLoading();
        oVue.$store
          .dispatch(GET_CAMPAIGNS_MANDANTE, oVue.mandante)
          .then((campaigns) => {
            campaigns.unshift({ campaignID: null, nombre: "" });
            oVue.campaigns = campaigns;
            KTUtil.removeLoading();
          });
      }
    },
    formatDate(date) {
      if (!date) return null;

      const [year, month, day] = date.split("-");
      return `${day}-${month}-${year}`;
    },
    async showTasks() {
      let oVue = this;
      oVue.dialogShowTask = false;

      if (oVue.fechaDesde && oVue.fechaHasta) {
        const oMomentDesde = moment(oVue.fechaDesdeOriginal);
        const oMomentHasta = moment(oVue.fechaHastaOriginal);

        if (oMomentDesde.diff(oMomentHasta) > 0) {
          Swal.fire({
            title: "Error!",
            text: "La fecha desde no puede ser mayor a la fecha hasta",
            icon: "warning",
            confirmButtonClass: "btn btn-secondary",
          });
          return;
        }
      }
      const aTaskData = await oVue.getTasksExport();
      if (aTaskData.length > 0) {
        oVue.tasks = aTaskData;
        oVue.dialogShowTask = true;
      }
    },
    async getTasksExport() {
      KTUtil.setLoading();
      let oVue = this;
      const oData = {
        mandante: oVue.mandante,
        campaign: oVue.campaign,
        fechaDesde: oVue.fechaDesdeOriginal,
        fechaHasta: oVue.fechaHastaOriginal,
      };
      const aAllTaskDataResponse = await oVue.$store.dispatch(
        GET_TASKS_EXPORT,
        oData
      );
      KTUtil.removeLoading();

      return aAllTaskDataResponse;
    },
    downloadExcelFile(filename, id, cols) {
      const wb = (XLSX.WorkBook = XLSX.utils.book_new());
      const elt = KTUtil.getById(id);
      const table = elt.querySelector("table");
      const ws = (XLSX.WorkSheet = XLSX.utils.table_to_sheet(table, {
        raw: true,
      }));

      let row = 0;
      for (let col = 0; col <= cols; col++) {
        const ref = XLSX.utils.encode_cell({ r: row, c: col });
        if (ws[ref]) {
          ws[ref].s = {
            font: {
              bold: true,
            },
          };
        }
      }

      XLSX.utils.book_append_sheet(wb, ws, filename);

      return XLSX.writeFile(wb, `${filename}.xlsx`);
    },
  },
  computed: {
    ...mapGetters(["currentUserPersonalInfo"]),
    formTitle() {
      return this.editedIndex === -1 ? "New Item" : "Edit Item";
    },
  },
  watch: {
    //eslint-disable-next-line no-unused-vars
    fechaDesdeOriginal(val) {
      this.fechaDesde = this.formatDate(this.fechaDesdeOriginal);
    },
    //eslint-disable-next-line no-unused-vars
    fechaHastaOriginal(val) {
      this.fechaHasta = this.formatDate(this.fechaHastaOriginal);
    },
  },
};
</script>
